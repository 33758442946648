export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const ERROR = 'ERROR';

export const FETCH_TIERS = 'FETCH_TIERS';
export const FETCH_PLAYLISTSONGS = 'FETCH_PLAYLISTSONGS';
export const FETCH_PLAYLIST = 'FETCH_PLAYLIST';
export const FETCH_PLAYLISTS = 'FETCH_PLAYLISTS';
export const FETCH_BAND = 'FETCH_BAND';
export const FETCH_BANDS = 'FETCH_BANDS';
export const FETCH_TITLES = 'FETCH_TITLES';
export const FETCH_VERSIONS = 'FETCH_VERSIONS';
export const FETCH_BOUNCES = 'FETCH_BOUNCES';
export const FETCH_RECORDING = 'FETCH_RECORDING';

export const FETCH_TITLE = 'FETCH_TITLE';
export const FETCH_VERSION = 'FETCH_VERSION';
export const FETCH_BOUNCE = 'FETCH_BOUNCE';

export const CREATE_TIER = 'CREATE_TIER';
export const CREATE_PLAYLISTSONG = 'CREATE_PLAYLISTSONG';
export const CREATE_TITLE = 'CREATE_TITLE';
export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export const CREATE_VERSION = 'CREATE_VERSION';
export const CREATE_BOUNCE = 'CREATE_BOUNCE';
export const CREATE_BAND = 'CREATE_BAND';
export const CREATE_RECORDING = 'CREATE_RECORDING';
export const COMBINE_RECORDINGS = 'COMBINE_RECORDINGS';

export const UPLOAD_STARTED = 'UPLOAD_STARTED';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

export const EDIT_TIER = 'EDIT_TIER';
export const EDIT_PLAYLIST = 'EDIT_PLAYLIST';
export const EDIT_PLAYLISTSONG = 'EDIT_PLAYLISTSONG';
export const EDIT_TITLE = 'EDIT_TITLE';
export const EDIT_VERSION = 'EDIT_VERSION';
export const EDIT_BOUNCE = 'EDIT_BOUNCE';
export const EDIT_BAND = 'EDIT_BAND';

export const DELETE_TIER = 'DELETE_TIER';
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const DELETE_PLAYLISTSONG = 'DELETE_PLAYLISTSONG';
export const DELETE_TITLE = 'DELETE_TITLE';
export const DELETE_VERSION = 'DELETE_VERSION';
export const DELETE_BOUNCE = 'DELETE_BOUNCE';
export const DELETE_BAND = 'DELETE_BAND';
export const CLEAR_RECORDINGS = 'CLEAR_RECORDINGS';
export const DELETE_RECORDING = 'DELETE_RECORDING';

export const PLAY_AUDIO = 'PLAY_AUDIO';
export const PAUSE_AUDIO = 'PAUSE_AUDIO';
export const QUEUE_SONGS = 'QUEUE_SONGS';
export const NEXT_SONG = 'NEXT_SONG';
export const CHANGE_VOLUME = 'CHANGE_VOLUME';
export const INITIALIZE_AUDIO = 'INITIALIZE_AUDIO';
export const ORDER_TIER = 'ORDER_TIER';

export const SELECT_VERSION = 'SELECT_VERSION';
export const SELECT_BOUNCE = 'SELECT_BOUNCE';
